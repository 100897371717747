<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      floating
      width="310"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="60px"
            max-width="60px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-slide-x-transition>
            <h4 class="text--primary">GOODLIFE MEMORIAL CHAPEL CO.</h4>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }"
                       :icon="icons.mdiHomeOutline"></nav-menu-link>
        <nav-menu-link
          v-if="position === 'CHAPEL STAFF'"
          title="Deposit Slip"
          :to="{ name: 'new-deposit-slip' }"
          :icon="icons.mdiBankTransfer"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'CHAPEL STAFF'"
          title="Payments"
          :to="{ name: 'payments' }"
          :icon="icons.mdiCashMultiple"
        ></nav-menu-link>
        <nav-menu-section-title
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="ADMIN INTERFACE"
        ></nav-menu-section-title>
        <nav-menu-link
          v-if="position === 'ADMIN' ||position === 'ADMIN SPECIAL'"
          title="LOAD OR BATCH"
          :to="{ name: 'load-or-batch' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' ||position === 'ADMIN SPECIAL'"
          title="TRANSMITTAL OR"
          :to="{ name: 'transmittal-load-or-batch' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' ||position === 'ADMIN SPECIAL'"
          title="BANK TALLY"
          :to="{ name: 'admin-bank-tally' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="Register Branch"
          :to="{ name: 'admin-register-branch' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="Register Officer"
          :to="{ name: 'admin-register-officer' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="Register User"
          :to="{ name: 'admin-register-users' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="Register Collector"
          :to="{ name: 'register-collector' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Approve for Print Contract"
          :to="{ name: 'approve-request-for-print' }"
        ></nav-menu-link>
        <nav-menu-group
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Deceased With Refunds"
          :icon="icons.mdiFormatListText"
        >
          <nav-menu-link
            title="With Refund"
            :to="{ name: 'list-of-deceased-with-refund' }"
          ></nav-menu-link>
          <nav-menu-link
            title="With Refund Reports"
            :to="{ name: 'list-of-deceased-with-refund-reports' }"
          ></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Admin Summary Collection Report"
          :to="{ name: 'admin-summary-collection-report' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Approved Request Casket"
          :to="{ name: 'approved-request-casket' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Approved For New Month"
          :to="{ name: 'approved-for-new-month' }"
        ></nav-menu-link>
        <nav-menu-section-title
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="COMMON INTERFACE"
        ></nav-menu-section-title>
        <nav-menu-section-title
          v-if="position === 'CHAPEL STAFF'"
          title="CHAPEL STAFF INTERFACE"
        ></nav-menu-section-title>
        <!--      Request-->
        <nav-menu-group v-if="position === 'CHAPEL STAFF'" title="Request" :icon="icons.mdiForum">
          <nav-menu-link title="Casket" :to="{ name: 'new-request-casket' }"></nav-menu-link>
          <nav-menu-link-dialog title="Gas Slip Purchase Order"
                                :to_do="'GAS SLIP FORM'"></nav-menu-link-dialog>
          <nav-menu-link title="For Contract Print"
                         :to="{ name: 'request-for-print' }"></nav-menu-link>
          <nav-menu-link title="Chapel Fund" :to="{ name: 'chapel-fund-request' }"></nav-menu-link>
          <nav-menu-link-kaagapay-dialog title="Kaagapay Form"
                                         :to_do="'KAAGAPAY FORM'"></nav-menu-link-kaagapay-dialog>
        </nav-menu-group>
        <!--      Request End-->
        <!--      Liquidations-->
        <nav-menu-group v-if="position === 'CHAPEL STAFF'" title="Liquidations"
                        :icon="icons.mdiCashLock">
          <nav-menu-link title="Gas Slip Liquidations"
                         :to="{ name: 'gas-slip-liquidation' }"></nav-menu-link>
          <nav-menu-link title="Chapel Fund Liquidations"
                         :to="{ name: 'chapel-fund-liquidations' }"></nav-menu-link>
        </nav-menu-group>
        <!--      Liquidations End-->
        <!--        Register-->
        <nav-menu-group v-if="position === 'CHAPEL STAFF'" title="Register"
                        :icon="icons.mdiPencilOutline">
          <nav-menu-link title="Auto Name" :to="{ name: 'new-auto-name' }"></nav-menu-link>
          <nav-menu-link title="New Deceased" :to="{ name: 'new-deceased' }"></nav-menu-link>
          <nav-menu-link title="New Dayong" :to="{ name: 'new-dayong-name' }"></nav-menu-link>
          <nav-menu-link title="New Kaagapay" :to="{ name: 'new-kaagapay' }"></nav-menu-link>
          <nav-menu-link title="New Callers" :to="{ name: 'new-callers' }"></nav-menu-link>
          <nav-menu-link title="New Sales Agent" :to="{ name: 'new-sales-agent' }"></nav-menu-link>
          <nav-menu-link
            title="Assign Room Accomodation"
            :to="{ name: 'new-assigned-room-accomodation' }"
          ></nav-menu-link>
        </nav-menu-group>
        <!--      Register End-->
        <!--      Search-->
        <nav-menu-group title="Search" :icon="icons.mdiAccountSearchOutline">
          <nav-menu-link title="Deceased" :to="{ name: 'search-deceased-client' }"></nav-menu-link>
          <nav-menu-link title="Kaagapay" :to="{ name: 'search-kaagapay-client' }"></nav-menu-link>
          <nav-menu-link title="OR # in Remittances"
                         :to="{ name: 'search-remittances' }"></nav-menu-link>
        </nav-menu-group>
        <!--      Search End-->
        <nav-menu-section-title v-if="position === 'CHAPEL STAFF'"
                                title="LOAD INTERFACE"></nav-menu-section-title>
        <nav-menu-link
          v-if="position === 'CHAPEL STAFF'"
          title="OR Series Service"
          :to="{ name: 'assign-OR' }"
          :icon="icons.mdiReceipt"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'CHAPEL STAFF'"
          title="OR Series Kaagapay"
          :to="{ name: 'assign-OR-kaagapay' }"
          :icon="icons.mdiReceipt"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'CHAPEL STAFF'"
          title="OR Re-Assign"
          :to="{ name: 're-assign-OR' }"
          :icon="icons.mdiPaperRollOutline"
        ></nav-menu-link>
        <nav-menu-link
          title="OR Monitoring"
          :to="{ name: 'or-monitoring' }"
          :icon="icons.mdiClipboardListOutline"
        ></nav-menu-link>

        <nav-menu-section-title title="VIEW INTERFACE"></nav-menu-section-title>
        <nav-menu-link title="Remittances" :to="{ name: 'remittances' }"
                       :icon="icons.mdiCash100"></nav-menu-link>
        <nav-menu-link title="Casket Inventory" :to="{ name: 'casket-inventory' }"
                       :icon="icons.mdiMonitorDashboard"></nav-menu-link>
        <nav-menu-link title="Book of Accounts" :to="{ name: 'book-of-accounts' }"
                       :icon="icons.mdiBookOpenVariant"></nav-menu-link>
        <nav-menu-section-title title="REPORT INTERFACE"></nav-menu-section-title>
        <nav-menu-link
          title="Bank Transactions"
          :to="{ name: 'bank-transaction-report' }"
          :icon="icons.mdiBank"
        ></nav-menu-link>
        <nav-menu-link
          title="Caller's Income"
          :to="{ name: 'callers-income' }"
          :icon="icons.mdiPhonePlus"
        ></nav-menu-link>
        <nav-menu-group title="Chapel Fund Reports" :icon="icons.mdiCreditCardCheck">
          <nav-menu-link
            v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
            title="Admin Summary"
            :to="{ name: '' }"
          ></nav-menu-link>
          <nav-menu-link title="By Particulars"
                         :to="{ name: 'chapel-fund-particulars' }"></nav-menu-link>
          <nav-menu-link title="Summary Report"
                         :to="{ name: 'chapel-fund-summary' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-group title="Manager Report" :icon="icons.mdiCurrencyPhp">
          <nav-menu-link title="CM Incentives"
                         :to="{ name: 'chapel-manager-incentives' }"></nav-menu-link>
          <nav-menu-link title="Summary" :to="{ name: 'cm-incentives-summary' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-group title="Officer Report" :icon="icons.mdiCurrencyPhp"
                        v-if="position === 'RCM' || position === 'ADMIN SPECIAL'">
          <nav-menu-link title="Officer Incentives"
                         v-if="position === 'RCM' || position === 'ADMIN SPECIAL'"
                         :to="{ name: 'rcm-incentives' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link
          title="List of Deceased"
          :to="{ name: 'list-of-deceased' }"
          :icon="icons.mdiHumanQueue"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="List of Kaagapay"
          :to="{ name: 'list-of-kaagapay' }"
          :icon="icons.mdiHumanCapacityIncrease"
        ></nav-menu-link>
        <nav-menu-link
          title="Summary Collection Report"
          :to="{ name: 'summary-collection-report' }"
          :icon="icons.mdiChartMultiline"
        ></nav-menu-link>
        <nav-menu-link
          title="Request Casket Report"
          :to="{ name: 'request-casket-report' }"
          :icon="icons.mdiChartTimeline"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN SPECIAL'"
          title="Unpaid Service"
          :to="{ name: 'unpaid-service' }"
          :icon="icons.mdiCashRemove"
        ></nav-menu-link>
        <nav-menu-link
          title="Unpaid Service"
          :to="{ name: 'unpaid-service-data' }"
          :icon="icons.mdiCashRemove"
        ></nav-menu-link>
        <nav-menu-link
          title="Gas Slip Report"
          :to="{ name: 'gas-slip-liquidation-report' }"
          :icon="icons.mdiShoePrint"
        ></nav-menu-link>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiHomeOutline,
    mdiAlphaTBoxOutline,
    mdiEyeOutline,
    mdiCreditCardOutline,
    mdiTable,
    mdiFileOutline,
    mdiFormSelect,
    mdiAccountCogOutline,
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiForum,
    mdiCashMultiple,
    mdiBankTransfer,
    mdiReceipt,
    mdiPaperRollOutline,
    mdiCash100,
    mdiBank,
    mdiCashLock,
    mdiPhonePlus,
    mdiCreditCardCheck,
    mdiHumanQueue,
    mdiHumanCapacityIncrease,
    mdiChartMultiline,
    mdiChartTimeline,
    mdiCashRemove,
    mdiShoePrint,
    mdiCurrencyPhp,
    mdiFormatListText,
    mdiClipboardListOutline,
    mdiMonitorDashboard,
    mdiBookOpenVariant,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import NavMenuLinkDialog from './components/NavMenuLinkGasSlipFormDialog.vue'
  import NavMenuLinkKaagapayDialog from './components/NavMenuLinkKaagapayFormDialog.vue'
  import {mapGetters} from 'vuex'

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
      NavMenuLinkDialog,
      NavMenuLinkKaagapayDialog,
    },
    computed: {
      ...mapGetters('authentication', ['position']),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        icons: {
          mdiHomeOutline,
          mdiAlphaTBoxOutline,
          mdiEyeOutline,
          mdiCreditCardOutline,
          mdiTable,
          mdiFileOutline,
          mdiFormSelect,
          mdiAccountCogOutline,
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiForum,
          mdiCashMultiple,
          mdiBankTransfer,
          mdiReceipt,
          mdiPaperRollOutline,
          mdiCash100,
          mdiBank,
          mdiCashLock,
          mdiPhonePlus,
          mdiCreditCardCheck,
          mdiHumanQueue,
          mdiHumanCapacityIncrease,
          mdiChartMultiline,
          mdiChartTimeline,
          mdiCashRemove,
          mdiShoePrint,
          mdiCurrencyPhp,
          mdiFormatListText,
          mdiClipboardListOutline,
          mdiMonitorDashboard,
          mdiBookOpenVariant,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
