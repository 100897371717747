<template>
  <div>
    <v-list-item
      @click="is_selected"
      link
      class="vertical-nav-menu-link"
      v-bind="$attrs"
      active-class="bg-gradient-primary white--text"
    >
      <v-list-item-icon>
        <v-icon :class="{ 'alternate-icon-small': !icon }" class="mx-auto">
          {{ icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
    </v-list-item>

    <v-dialog v-model="dialog_show" persistent scrollable max-width="400">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">Kaagapay Form</h4></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid_form" lazy-validation>
              <v-flex xs12>
                <v-layout column wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="count"
                      label="How Many Pages Do you Print?"
                      class="mx-2"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="saving === false">
            <v-btn class="mx-1" color="primary" @click="print_kaagapay_form()">Print</v-btn>
            <v-btn class="mx-1" color="error" @click="close_dialog">Close</v-btn>
          </div>
          <div v-else-if="saving === true">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiCheckboxBlankCircleOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'

  export default {
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['year', 'branch_id', 'month_of', 'branch_address', 'branch_contact_no', 'branch']),
      ...mapGetters('system_data', ['company_logo']),
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        default: undefined,
      },
      to_do: String,
    },
    setup() {
      return {
        alternateIcon: mdiCheckboxBlankCircleOutline,
      }
    },
    data() {
      return {
        dialog_show: false,
        valid_form: false,
        count: 50,
        saving: false,
      }
    },
    methods: {
      ...mapActions('transmital_kmf', ['get_last_print_kmf', 'save_transmital_kmf']),
      is_selected() {
        this.dialog_show = true
      },
      close_dialog() {
        this.dialog_show = false
      },
      print_kaagapay_form() {
        this.saving = true
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_last_print_kmf(data).then(response => {
          var retreive_last_po = parseInt(response.data.end)
          console.log(response.data === '')
          if (response.data === '') {
            retreive_last_po = 0
          }

          this.save_transmital_kmf({
            branch_id: this.branch_id,
            start: retreive_last_po + 1,
            end: retreive_last_po + 50,
            extension: '',
            remarks: '',
            month_of: this.month_of,
          })
            .then(() => {
              this.saving = false

              var imgData = this.company_logo
              var po_no = 0

              let page_array = []
              var last_po = retreive_last_po
              for (var pages = 1; pages <= 50; pages++) {
                last_po = last_po + 1
                po_no = last_po
                page_array.push(
                  [
                    {
                      columns: [
                        {image: imgData, width: 54, height: 54, style: 'logo'},
                        {
                          stack: [
                            'GOODLIFE MEMORIAL CHAPEL CO.',
                            {text: this.branch_address, style: 'subheader'},
                            {
                              text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                              style: 'subheader',
                            },
                          ],
                          style: 'header',
                        },
                      ],
                    },
                    '__________________________________________________________________________________________________',
                    {
                      stack: [
                        {
                          columns: [
                            {text: 'Branch: ' + this.branch, style: 'main_info'},
                            {
                              text: 'GOODLIFE CARES MEMBERSHIP FORM',
                              style: {
                                fontSize: 16,
                                alignment: 'center',
                                bold: true,
                                color: 'blue',
                              },
                            },
                            {
                              text: 'KMF No.: ' + po_no,
                              style: {
                                margin: [0, 2, 0, 2],
                                fontSize: 13,
                                alignment: 'right',
                                color: 'red',
                                bold: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                    '__________________________________________________________________________________________________',
                    {
                      columns: [
                        {
                          text: 'It is a membership organization for individual that wishes to participate in the collaboration to support each other when unexpected circumstances might happen, it is also designed to include Family members.',
                          style: {alignment: 'justify', fontSize: 10},
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'I. NAME', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: '______________________________'},
                        {text: '______________________________'},
                        {text: '______________________________'},
                      ],
                    },
                    {
                      columns: [
                        {text: 'LAST NAME', style: 'main_info'},
                        {text: 'FIRST NAME', style: 'main_info'},
                        {text: 'MIDDLE NAME', style: 'main_info'},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'II. COMPLETE ADDRESS', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'III. DATE OF BIRTH: _______________________ AGE: __________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'IV. RELIGION: _____________________________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'V. GENDER: _____________________________', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'VI. STATUS: _____________________________', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'VII. OCCUPATION: _____________________________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    {
                      stack: [
                        {text: 'VIII. BENEFITS', style: {fontSize: 14}},
                        {
                          style: {alignment: 'justify', fontSize: 10},
                          ul: [
                            'The Member together of his/her Immediate Family Members are entitled to 30% discount to all types of service rendered by Goodlife Memorial Chapel Co.',
                            'The Member has the privilege to use the membership to all his/her relatives and in laws and  will received 20% discount to all types of services.',
                            'The Member has the privilege to use the membership to non relatives or non member of GOODLIFE CARES and will received 10% discount or 10% callers fee to all types of services.',
                            'The Membership will automatically terminated in the system when the member use the kaagapay program',
                            'The discounts will automatically deducted to what type of casket description choose by the member or bereaved family.',
                          ],
                        },
                      ],
                    },
                    {
                      stack: [
                        {text: 'IX. SERVICE PACKAGES ARE FOLLOWS', style: {fontSize: 14}},
                        {
                          style: {alignment: 'justify', fontSize: 9},
                          ul: [
                            'Free Use of Tables',
                            'Free Use of Chairs',
                            'Free Use of Dispenser or Heater',
                            'Flower',
                            'Tarpaulin',
                            'Ribbons',
                            'Guest Book',
                            'Service Equipments',
                            '25KM Free',
                            '9 Days Viewing Residence',
                            'Frame',
                          ],
                        },
                      ],
                    },
                    {
                      columns: [
                        {text: 'X. PAYMENT DATA', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'AMOUNT PAID', style: {fontSize: 10}},
                        {text: '350 pesos only', style: {fontSize: 15}},
                        {text: 'EFFECTIVE DATE: ____________________', style: {fontSize: 10}},
                      ],
                    },
                    '\n',
                    {text: 'OFFICIAL RECEIPT NUMBER: _____________________', style: {fontSize: 10}},
                    '\n',
                    '\n',
                    {
                      columns: [
                        {text: '____________________________________', style: {fontSize: 14}},
                        {text: '____________________________________', style: {fontSize: 14}},
                      ],
                    },
                    {
                      columns: [
                        {text: 'WITNESSED BY CHAPEL REPRESENTATIVE', style: {fontSize: 12}},
                        {text: 'SIGNATURE OF GOODLIFE CARES MEMBER', style: {fontSize: 12}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: ' ',
                        },
                        {
                          table: {
                            body: [
                              [
                                {
                                  fillColor: '#f7e307',
                                  stack: [
                                    {
                                      text: 'GOODLIFE COPY',
                                      style: {fontSize: 16, color: 'blacK'},
                                    },
                                  ],
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ],
                  [
                    {
                      columns: [
                        {image: imgData, width: 54, height: 54, style: 'logo'},
                        {
                          stack: [
                            'GOODLIFE MEMORIAL CHAPEL CO.',
                            {text: this.branch_address, style: 'subheader'},
                            {
                              text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                              style: 'subheader',
                            },
                          ],
                          style: 'header',
                        },
                      ],
                    },
                    '__________________________________________________________________________________________________',
                    {
                      stack: [
                        {
                          columns: [
                            {text: 'Branch: ' + this.branch, style: 'main_info'},
                            {
                              text: 'GOODLIFE CARES MEMBERSHIP FORM',
                              style: {
                                fontSize: 16,
                                alignment: 'center',
                                bold: true,
                                color: 'blue',
                              },
                            },
                            {
                              text: 'KMF No.: ' + po_no,
                              style: {
                                margin: [0, 2, 0, 2],
                                fontSize: 13,
                                alignment: 'right',
                                color: 'red',
                                bold: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                    '__________________________________________________________________________________________________',
                    {
                      columns: [
                        {
                          text: 'It is a membership organization for individual that wishes to participate in the collaboration to support each other when unexpected circumstances might happen, it is also designed to include Family members.',
                          style: {alignment: 'justify', fontSize: 10},
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'I. NAME', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: '______________________________'},
                        {text: '______________________________'},
                        {text: '______________________________'},
                      ],
                    },
                    {
                      columns: [
                        {text: 'LAST NAME', style: 'main_info'},
                        {text: 'FIRST NAME', style: 'main_info'},
                        {text: 'MIDDLE NAME', style: 'main_info'},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'II. COMPLETE ADDRESS', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'III. DATE OF BIRTH: _______________________ AGE: __________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'IV. RELIGION: _____________________________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'V. GENDER: _____________________________', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'VI. STATUS: _____________________________', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: 'VII. OCCUPATION: _____________________________',
                          style: {fontSize: 14}
                        },
                      ],
                    },
                    {
                      stack: [
                        {text: 'VIII. BENEFITS', style: {fontSize: 14}},
                        {
                          style: {alignment: 'justify', fontSize: 10},
                          ul: [
                            'The Member together of his/her Immediate Family Members are entitled to 30% discount to all types of service rendered by Goodlife Memorial Chapel Co.',
                            'The Member has the privilege to use the membership to all his/her relatives and in laws and  will received 20% discount to all types of services.',
                            'The Member has the privilege to use the membership to non relatives or non member of GOODLIFE CARES and will received 10% discount or 10% callers fee to all types of services.',
                            'The Membership will automatically terminated in the system when the member use the kaagapay program',
                            'The discounts will automatically deducted to what type of casket description choose by the member or bereaved family.',
                          ],
                        },
                      ],
                    },
                    {
                      stack: [
                        {text: 'IX. SERVICE PACKAGES ARE FOLLOWS', style: {fontSize: 14}},
                        {
                          style: {alignment: 'justify', fontSize: 9},
                          ul: [
                            'Free Use of Tables',
                            'Free Use of Chairs',
                            'Free Use of Dispenser or Heater',
                            'Flower',
                            'Tarpaulin',
                            'Ribbons',
                            'Guest Book',
                            'Service Equipments',
                            '25KM Free',
                            '9 Days Viewing Residence',
                            'Frame',
                          ],
                        },
                      ],
                    },
                    {
                      columns: [
                        {text: 'IX. PAYMENT DATA', style: {fontSize: 14}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {text: 'AMOUNT PAID', style: {fontSize: 10}},
                        {text: '350 pesos only', style: {fontSize: 15}},
                        {text: 'EFFECTIVE DATE: ____________________', style: {fontSize: 10}},
                      ],
                    },
                    '\n',
                    {text: 'OFFICIAL RECEIPT NUMBER: _____________________', style: {fontSize: 10}},
                    '\n',
                    '\n',
                    {
                      columns: [
                        {text: '____________________________________', style: {fontSize: 14}},
                        {text: '____________________________________', style: {fontSize: 14}},
                      ],
                    },
                    {
                      columns: [
                        {text: 'WITNESSED BY CHAPEL REPRESENTATIVE', style: {fontSize: 12}},
                        {text: 'SIGNATURE OF GOODLIFE CARES MEMBER', style: {fontSize: 12}},
                      ],
                    },
                    '\n',
                    {
                      columns: [
                        {
                          text: ' ',
                        },
                        {
                          table: {
                            body: [
                              [
                                {
                                  fillColor: '#0039f5',
                                  stack: [
                                    {
                                      text: 'CLIENT COPY',
                                      style: {fontSize: 16, color: 'white'},
                                    },
                                  ],
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ]
                )
              }
              //call pdfmake
              var pdfMake = require('pdfmake/build/pdfmake.js')
              if (pdfMake.vfs == undefined) {
                var pdfFonts = require('pdfmake/build/vfs_fonts.js')
                pdfMake.vfs = pdfFonts.pdfMake.vfs
              }
              var docDefinition = {
                pageSize: {
                  width: 612,
                  height: 936,
                },
                pageOrientation: 'portrait',
                content: [
                  page_array
                ],
                styles: {
                  header: {
                    fontSize: 21,
                    bold: true,
                    alignment: 'left',
                    margin: [6, 6, 0, 5], //[left, top, right, bottom]
                  },
                  subheader: {
                    fontSize: 11,
                  },
                  logo: {
                    alignment: 'center',
                    margin: [0, 0, 0, 0], //[left, top, right, bottom]
                  },
                  printed_label: {
                    alignment: 'right',
                    fontSize: 9,
                    margin: [0, 0, 0, 0], //[left, top, right, bottom]
                  },
                  main_data: {
                    margin: [0, 2, 0, 2],
                    fontSize: 11,
                    bold: true,
                    alignment: 'left',
                  },
                  main_data_2: {
                    margin: [0, 2, 0, 2],
                    fontSize: 8,
                    bold: true,
                    alignment: 'left',
                  },
                  main_info: {
                    margin: [0, 2, 0, 2],
                    fontSize: 10,
                    alignment: 'left',
                  },
                },
              }
              pdfMake.createPdf(docDefinition).open()
              this.close_dialog()
            })
            .catch(error => {
              console.log(error)
              this.saving = false
            })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  // @import '~vuetify/src/styles/styles.sass';

  .alternate-icon-small {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }

  .vertical-nav-menu-link {
    &.v-list-item--active {
      box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
      @include elevationTransition();
    }
  }
</style>
