<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230"
            content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span class="text--primary font-weight-semibold mb-n1">
              {{ name }}
            </span>
            <small class="text--disabled text-capitalize">{{ position }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <v-list-item link @click="is_change_password = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link v-if=" position==='ADMIN SPECIAL' && branch_id>1"
                     @click="load_transmital_or_dialog = true ">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiContentSaveMove }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Load Transmital Or</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link
                     v-if="position==='CHAPEL STAFF'  && date_is_can_new_month && can_new_month===1 "
                     @click="start_new_month_transaction">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiReload }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start New Month Transaction</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link
                     v-if="position==='CHAPEL STAFF'   && date_is_can_new_month&& can_new_month===0"
                     @click="request_new_month">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiReload }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Request New Month</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

<!--        &lt;!&ndash; Profile &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiAccountOutline }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Profile</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

<!--        &lt;!&ndash; Email &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiEmailOutline }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Inbox</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

<!--        &lt;!&ndash; Chat &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiChatOutline }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Chat</v-list-item-title>-->
<!--          </v-list-item-content>-->

<!--          <v-list-item-action>-->
<!--            <v-badge inline color="error" content="2"></v-badge>-->
<!--          </v-list-item-action>-->
<!--        </v-list-item>-->

<!--        <v-divider class="my-2"></v-divider>-->

<!--        &lt;!&ndash; Settings &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiCogOutline }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Settings</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

<!--        &lt;!&ndash; Pricing &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiCurrencyUsd }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Pricing</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

<!--        &lt;!&ndash; FAQ &ndash;&gt;-->
<!--        <v-list-item link>-->
<!--          <v-list-item-icon class="me-2">-->
<!--            <v-icon size="22">-->
<!--              {{ icons.mdiHelpCircleOutline }}-->
<!--            </v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>FAQ</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <load-transmital-or
      v-on:dialog_value="on_restore_db_file_close"
      :dialog_show="load_transmital_or_dialog"
    />

    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiEmailOutline,
    mdiCheckboxMarkedOutline,
    mdiChatOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiContentSaveMove,
  } from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'
  import loadTransmitalOr from './dialogs/LoadTransmitalOr'
  import ChangePassword from '../../views/dashboard/ChangePassword'

  export default {
    components: {
      ChangePassword,
      loadTransmitalOr,
    },
    data() {
      return {
        show: false,
        txt: '',
        date_is_can_new_month: false,
        load_transmital_or_dialog: false,
        is_change_password: false,

        icons: {
          mdiChatOutline,
          mdiCogOutline,
          mdiHelpCircleOutline,
          mdiLogoutVariant,
          mdiContentSaveMove,
        },
      }
    },
    mounted() {
      this.conditions_for_new_month()
    },
    watch: {
      month_end: function () {
        this.conditions_for_new_month()
      },
    },
    computed: {
      ...mapGetters('authentication', ['id_no', 'name', 'position', 'branch_id', 'branch', 'can_new_month', 'month_of', 'month_end']),
    },
    methods: {
      ...mapActions('authentication', ['logout_credentials']),
      ...mapActions('chapel_manager_incentives', ['get_clients_for_cm_incentives_present_new']),
      ...mapActions('transaction_months', ['start_new_transaction_month']),
      ...mapActions('branch_information', ['request_for_new_month']),
      on_restore_db_file_close(value) {
        this.load_transmital_or_dialog = value
      },
      conditions_for_new_month() {
        var end_date = moment(this.month_end, 'MMMM DD, YYYY')
        if (moment(end_date).isBefore(moment())) {
          this.date_is_can_new_month = true
        }
      },
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      request_new_month() {
        const data = new FormData()
        data.append('id', this.branch_id)
        this.show = true
        this.txt = 'REQUESTING FOR NEW MONTH...'
        this.request_for_new_month(data)
          .then(() => {
          }).catch(error => {
          console.log(error)
          this.show = false
          this.txt = ''
        })
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.txt = ''
          this.show = false
        }, 2000)
      },
      start_new_month_transaction() {
        var start = moment().clone().startOf('month').format('MMMM DD, YYYY')
        var end = moment().clone().endOf('month').format('MMMM DD, YYYY')
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        data.append('is_start_new_month', 1)
        this.show = true
        this.txt = 'COLLECTING DATA & SAVING DATA ON CHAPEL MANAGER INCENTIVES'
        this.get_clients_for_cm_incentives_present_new(data)
          .then(() => {
            const data2 = new FormData()
            data2.append('month_of', moment().format('MMMM YYYY'));
            data2.append('year', moment().year());
            data2.append('month_start', start);
            data2.append('month_end', end);
            data2.append('branch_id', this.branch_id);
            this.txt = 'STARTING NEW MONTH TRANSACTIONS...'
            this.start_new_transaction_month(data2)
              .then(response => {
                this.txt = response.data + ' AND AUTOMATICALLY LOGOUT!'
                this.logout()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.txt = ''
          this.show = false
        }, 2000)
      }
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
