<template>
  <div>
    <v-list-item
      @click="is_selected"
      link
      class="vertical-nav-menu-link"
      v-bind="$attrs"
      active-class="bg-gradient-primary white--text"
    >
      <v-list-item-icon>
        <v-icon :class="{ 'alternate-icon-small': !icon }" class="mx-auto">
          {{ icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
    </v-list-item>

    <v-dialog v-model="gas_slip_dialog_show" persistent scrollable max-width="400">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">Gas Slip Form</h4></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid_form" lazy-validation>
              <v-flex xs12>
                <v-layout column wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="count"
                      label="How Many Pages Do you Print?"
                      class="mx-2"
                      type="number"
                      :min="1"
                      :rules="rules.default_no_empty_rule"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="saving === false">
            <v-btn class="mx-1" color="primary" @click="print_gas_slip()">Save</v-btn>
            <v-btn class="mx-1" color="error" @click="close_dialog">Close</v-btn>
          </div>
          <div v-else-if="saving === true">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCheckboxBlankCircleOutline } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['year', 'branch_id', 'month_of', 'branch_address', 'branch_contact_no']),
    ...mapGetters('system_data', ['company_logo']),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    to_do: String,
  },
  setup() {
    return {
      alternateIcon: mdiCheckboxBlankCircleOutline,
    }
  },
  data() {
    return {
      gas_slip_dialog_show: false,
      valid_form: false,
      count: 0,
      saving: false,
    }
  },
  methods: {
    ...mapActions('gas_slip_po', ['count_po_no_genarated_year', 'save_gas_slip_po']),
    is_selected() {
      this.gas_slip_dialog_show = true
    },
    close_dialog() {
      this.gas_slip_dialog_show = false
    },
    print_gas_slip() {
      this.saving = true
      const data = new FormData()
      data.append('branch_id', this.branch_id)
      data.append('year', this.year)
      this.count_po_no_genarated_year(data).then(response => {
        var retreive_last_po = response.data
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var po_no = 0
          var po_no_2 = 0

          let page_array = []
          var last_po = retreive_last_po
          for (var pages = 1; pages <= this.count; pages++) {
            last_po = last_po + 1
            po_no = last_po

            last_po = last_po + 1
            po_no_2 = last_po
            page_array.push(
              [
                [
                  {
                    columns: [
                      { image: imgData, width: 45, height: 45, style: 'logo' },
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          { text: this.branch_address, style: 'subheader' },
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809',
                            style: 'subheader',
                          },
                          {
                            text: 'Contact #: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'GAS SLIP',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                        },
                      },
                    ],
                  },
                  {
                    table: {
                      body: [
                        [
                          [
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'left',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PO #: ' + po_no + '-' + this.year,
                                  style: {
                                    fontSize: 11,
                                    alignment: 'left',
                                    bold: true,
                                    color: 'red',
                                  },
                                },
                                {
                                  text: 'Date:_________________',
                                  style: {
                                    fontSize: 11,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              table: {
                                body: [
                                  [
                                    {
                                      text: 'PRICE/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'QTY/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'PARTICULARS',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'TOTAL AMOUNT',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'DIESEL',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'GASOLINE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'KEROSENE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                ],
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'TOTAL:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PLATE #:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: '________________________________________________',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 20],
                            },
                            {
                              text: 'Signature Over Printed Name of Goodlife',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: 'Representative',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: 'GASOLINE STATION COPY',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 15],
                            },
                          ],
                        ],
                      ],
                    },
                  },
                ],
                [
                  {
                    columns: [
                      { image: imgData, width: 45, height: 45, style: 'logo' },
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          { text: this.branch_address, style: 'subheader' },
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809',
                            style: 'subheader',
                          },
                          {
                            text: 'Contact #: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'GAS SLIP',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                        },
                      },
                    ],
                  },
                  {
                    table: {
                      body: [
                        [
                          [
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'left',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PO #: ' + po_no + '-' + this.year,
                                  style: {
                                    fontSize: 11,
                                    alignment: 'left',
                                    bold: true,
                                    color: 'red',
                                  },
                                },
                                {
                                  text: 'Date:_________________',
                                  style: {
                                    fontSize: 11,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              table: {
                                body: [
                                  [
                                    {
                                      text: 'PRICE/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'QTY/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'PARTICULARS',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'TOTAL AMOUNT',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'DIESEL',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'GASOLINE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'KEROSENE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                ],
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'TOTAL:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PLATE #:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: '________________________________________________',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 20],
                            },
                            {
                              text: 'Signature Over Printed Name of Goodlife',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: 'Representative',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: 'GOODLIFE CHAPELS COPY',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 15],
                            },
                          ],
                        ],
                      ],
                    },
                  },
                ],
              ],
              [
                [
                  {
                    columns: [
                      { image: imgData, width: 45, height: 45, style: 'logo' },
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          { text: this.branch_address, style: 'subheader' },
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809',
                            style: 'subheader',
                          },
                          {
                            text: 'Contact #: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'GAS SLIP',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                        },
                      },
                    ],
                  },
                  {
                    table: {
                      body: [
                        [
                          [
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'left',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PO #: ' + po_no_2 + '-' + this.year,
                                  style: {
                                    fontSize: 11,
                                    alignment: 'left',
                                    bold: true,
                                    color: 'red',
                                  },
                                },
                                {
                                  text: 'Date:_________________',
                                  style: {
                                    fontSize: 11,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              table: {
                                body: [
                                  [
                                    {
                                      text: 'PRICE/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'QTY/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'PARTICULARS',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'TOTAL AMOUNT',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'DIESEL',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'GASOLINE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'KEROSENE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                ],
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'TOTAL:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PLATE #:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: '________________________________________________',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 20],
                            },
                            {
                              text: 'Signature Over Printed Name of Goodlife',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: 'Representative',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: 'GASOLINE STATION COPY',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 15],
                            },
                          ],
                        ],
                      ],
                    },
                  },
                ],
                [
                  {
                    columns: [
                      { image: imgData, width: 45, height: 45, style: 'logo' },
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          { text: this.branch_address, style: 'subheader' },
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809',
                            style: 'subheader',
                          },
                          {
                            text: 'Contact #: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'GAS SLIP',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                        },
                      },
                    ],
                  },
                  {
                    table: {
                      body: [
                        [
                          [
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'left',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PO #: ' + po_no_2 + '-' + this.year,
                                  style: {
                                    fontSize: 11,
                                    alignment: 'left',
                                    bold: true,
                                    color: 'red',
                                  },
                                },
                                {
                                  text: 'Date:_________________',
                                  style: {
                                    fontSize: 11,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              table: {
                                body: [
                                  [
                                    {
                                      text: 'PRICE/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'QTY/L',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'PARTICULARS',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'TOTAL AMOUNT',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'DIESEL',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'GASOLINE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: 'KEROSENE',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                  [
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                    {
                                      text: ' ',
                                      style: {
                                        fontSize: 10,
                                        alignment: 'center',
                                      },
                                    },
                                  ],
                                ],
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'TOTAL:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              columns: [
                                {
                                  text: 'PLATE #:_________________',
                                  style: {
                                    fontSize: 10,
                                    alignment: 'right',
                                    bold: true,
                                  },
                                },
                              ],
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: '________________________________________________',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 20],
                            },
                            {
                              text: 'Signature Over Printed Name of Goodlife',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: 'Representative',
                              style: {
                                fontSize: 11,
                                alignment: 'center',
                                bold: true,
                              },
                            },
                            {
                              text: ' ',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                            },
                            {
                              text: 'GOODLIFE CHAPELS COPY',
                              style: {
                                fontSize: 11,
                                alignment: 'right',
                                bold: true,
                              },
                              margin: [0, 15],
                            },
                          ],
                        ],
                      ],
                    },
                  },
                ],
              ],
            )
          }
          this.save_gas_slip_po({
            branch_id: this.branch_id,
            po_no: retreive_last_po + 1,
            date: moment().format('MMMM D, YYYY'),
            month_of: this.month_of,
            year: this.year,
            pages: this.count,
          })
            .then(() => {
              this.saving = false
            })
            .catch(error => {
              console.log(error)
              this.saving = false
            })
          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                table: {
                  body: page_array,
                },
              },
            ],
            styles: {
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              header: {
                fontSize: 9,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 5,
              },

              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving = false
          this.close_dialog()
        } else {
          this.saving = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
